@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.content {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    margin-top: 50px;
    margin-bottom: 50px;
    padding-left: 72px;
    padding-right: 72px;
}

.readmorebtn {
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 20%;
    padding-left: 0 !important;
}

.content {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    height: 35%;
    overflow: hidden;
}

.casestudydetail {
    height: 20%;
    display: flex;
    margin-bottom: 5px;
}

.contentheading {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    height: 20%;
}

.cardimage {
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    height: 250px;
}

.card {
    /* height: fit-content; */
    border: none;
    border-radius: 10px;
}

.backcolor {
    box-shadow: 0px 20px 90px 0px #07003B1F;
    border-radius: 10px;
}

.navbutton {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    border: 1px solid #EFEFEF !important;
    border-radius: 54px !important;
}

.detailcontent {
    font-size: 12px;
    font-weight: 400;
    color: #4A5568;
    margin-left: 2px;
}

.engineers {
    height: fit-content;
    border-right: 0.5px solid #000000;
    padding-right: 10px;
}

.month {
    height: fit-content;
    border-right: 0.5px solid #000000;
    margin-left: 10px;
    padding-right: 10px;
}

.app {
    height: fit-content;
    margin-left: 10px;
}

.navscroll::-webkit-scrollbar {
    display: none;
}

.navscroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media only screen and (max-width: 1440px) {
    .navbutton {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .navbutton {
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding: 0;
        margin-top: 40px;
        margin-bottom: 0;
    }

    .navbutton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 690px) {
    .navbar {
        width: 650px;
    }

    .navscroll {
        overflow: auto;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 0;
        padding-right: 0;
        margin-top: 30px;
        margin-bottom: 0;
    }

    .card {
        height: 380px;
    }

    .contentheading {
        font-size: 16px;
    }

    .readmorebtn {
        font-size: 14px;
    }

    .content {
        font-size: 14px;
    }

    .navbar {
        width: 650px;
    }

    .navscroll {
        margin-left: 10px;
    }
}