.maindiv {
    padding-top: 80px;
}

.rowpadding {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.software {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
}

.development {
    background: linear-gradient(#6EB7D7, #0B618E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.company {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
    line-height: 36px;
}

.spancontent {
    color: #4A5568;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    line-height: 47px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.springimage {
    position: absolute;
    bottom: -290px;
    left: -75px;
    z-index: -1;
}

.modernizationimage {
    max-width: 100%;
    height: auto;
}

.getquotebtn:hover,
.getquotebtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #FFF !important;
    font-size: 16px;
    font-weight: 500;
    border: none;
}

@media only screen and (max-width: 1650px) {
    .springimage {
        bottom: -240px;
    }
}

@media only screen and (max-width: 1600px) {
    .springimage {
        bottom: -280px;
    }
}

@media only screen and (max-width: 1440px) {
    .springimage {
        bottom: -345px;
    }
}

@media only screen and (max-width: 1350px) {
    .springimage {
        bottom: -300px;
    }
}

@media only screen and (max-width: 1024px) {
    .rowpadding {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
    }

    .springimage {
        display: none;
    }

    .content {
        font-size: 16px;
        line-height: 30px;
    }

    .spancontent {
        font-size: 18px;
        line-height: 40px;
    }

    .mapimage,
    .appimage,
    .chooseappimage {
        height: 100%;
    }

    .heading {
        font-size: 35px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 35px;
    }

    .springimage {
        display: none;
    }
}

@media only screen and (max-width: 768px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 30px;
    }

    .spancontent {
        font-size: 16px;
        line-height: 36px;
    }

    .content {
        font-size: 16px;
        line-height: 30px;
    }

    .rowpadding {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }

    .heading {
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px) {
    .rowpadding {
        padding-left: 10px;
        padding-right: 10px;
    }
    .spancontent {
        font-size: 16px;
        line-height: 30px;
        margin-top: 20px;
    }

    .content {
        font-size: 14px;
        line-height: 26px;
    }

    .getquotebtn {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 28px;
    }

    .heading {
        font-size: 28px;
    }
}

@media only screen and (max-width: 320px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 26px;
    }

    .heading {
        font-size: 26px;
    }
}