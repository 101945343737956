.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.rowpadding {
    position: relative;
    padding-top: 60px;
}

.springimage {
    position: absolute;
    top: -390px;
    right: -70px;
    z-index: -1;
}

.detailbtn,
.detailbtn:hover {
    border: none;
    outline: none;
    position: relative;
    border-radius: 5px;
    background: linear-gradient(to right, #68B1D2 0%, #0B618E 100%);
    border: 1px solid;
    cursor: pointer;
    border: none;
}

.detailbtn::before {
    content: " View Details";
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    font-size: 16px;
    background-color: white;
    border-radius: 4px;
    color: black;
}


.accordianheading {
    color: #1A202C;
    font-size: 32px;
    font-weight: 500;
}

.accordian {
    background-color: #FDFDFF;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    border-radius: 10px;
}

.hiresmall {
    color: #718096;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0
}

.hirecontentsmall {
    color: #4A5568;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0
}

.collapse {
    border-top: 1px solid #9B9B9B
}

.collapscontent {
    font-size: 22px;
    line-height: 35px;
    font-weight: 500;
    color: #4A5568;
    margin-top: 30px;
}

.collapscontent1 {
    font-size: 22px;
    font-weight: 500;
    color: #0B618E;
    margin-top: 30px;
    margin-bottom: 25px;
}

.collapscontent2 {
    font-size: 17px;
    font-weight: 500;
    color: #4A5568;
}

.icons {
    color: #0B618E;
    margin-top: 3px;
}

.applybtn:hover,
.applybtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #B7CAD3;
    font-size: 20px;
    font-weight: 500;
    border: none;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
}

@media only screen and (max-width:1024px) {
    .springimage {
        display: none;
    }

    .rowpadding {
        padding-top: 40px;
    }

    .maindiv {
        padding-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .accordianheading {
        font-size: 26px;
    }

    .hirecontentsmall {
        font-size: 18px;
    }

    .hiresmall {
        font-size: 16px;
    }

    .detailbtn {
        padding: 6px;
        font-size: 18px;
    }

    .collapscontent {
        font-size: 20px;
        line-height: 30px;
    }

    .collapscontent1 {
        font-size: 20px;
    }

    .collapscontent2 {
        font-size: 16px;
    }
}

@media only screen and (max-width:800px) {
    .svg {
        width: 25px;
    }

    .hirecontentsmall {
        font-size: 16px;
    }

    .hiresmall {
        font-size: 14px;
    }

    .detailbtn {
        font-size: 16px;
    }
}

@media only screen and (max-width:768px) {

    .detailbtn::before {
        font-size: 14px;
    }

    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width:425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
    }

    .rowpadding {
        padding-top: 30px;
    }

    .accordianheading {
        font-size: 22px;
    }

    .hirecontentsmall {
        font-size: 14px;
    }

    .hiresmall {
        font-size: 12px;
    }

    .svg {
        width: 22px;
    }

    .detailbtn {
        font-size: 14px;
    }

    .collapscontent {
        font-size: 16px;
        margin-top: 15px;
    }

    .collapscontent1 {
        font-size: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .collapscontent2 {
        font-size: 14px;
    }

    .applybtn,
    .applybtn:hover {
        font-size: 14px;
    }
}

@media only screen and (max-width:375px) {
    .collapscontent {
        font-size: 14px;
        line-height: 26px;
    }

    .collapscontent2 {
        margin-bottom: 8px;
    }

    .accordianheading {
        font-size: 20px;
    }

    .svg {
        width: 20px;
    }
}