.maindiv {
    margin-top: 50px;
    margin-top: 60px;
}

.detailcontent {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    margin-top: 15px;
    text-align: center;
}

.svg {
    background-color: #00FE93;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg1 {
    background-color: #FF586E;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg2 {
    background-color: #FFA808;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg3 {
    background-color: #24CCFF;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square {
    height: 200px;
    box-shadow: 0px 4px 29px 0px #6EB7D757;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
}

.industrydetail {
    background-color: #E1EFF6;
    padding: 70px;
    margin-bottom: 70px;
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .industrydetail {
        padding: 40px;
        margin-bottom: 40px;
    }

    .detailcontent {
        font-size: 18px;
    }
}

@media only screen and (max-width: 900px) {

    .industrydetail {
        padding: 30px;
    }

    .detailcontent {
        font-size: 16px;
    }

    .industrydimage {
        height: 50px;
    }

    .square {
        height: 160px;
    }
}

@media only screen and (max-width: 768px) {
    .industrydetail {
        padding: 20px;
        margin-bottom: 30px;
    }

    .detailcontent {
        font-size: 14px;
    }

    .maindiv {
        margin-top: 30px;
    }
}

@media only screen and (max-width: 425px) {
    .square {
        height: 130px;
    }

    .industrydimage {
        height: 40px;
    }

    .industrydetail {
        padding: 10px;
    }

}