.maindiv {
    padding-top: 65px;
    padding-bottom: 65px;
    position: relative;
    top: 30px;
    width: 100%;
    padding-left: 70px;
    padding-right: 70px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.headingcontent {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.subheading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 95px;
    transform: translateX(-50%);
    width: 185px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.devloperimg {
    height: 45px;
    width: 45px;
    border-radius: 50px;
}

.content {
    margin-bottom: 0 !important;
    color: #4A5568;
    font-size: 16px;
    font-weight: 500;
}

code {
    color: #1A202C;
}

.blogcontent {
    margin-bottom: 0 !important;
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
}

.listcontent {
    margin-bottom: 0 !important;
    color: #4A5568;
    font-size: 20px;
    font-weight: 500;
}

.devloperrole {
    margin-bottom: 0 !important;
    color: #4A5568;
    font-size: 16px;
    font-weight: 400;
}

.devloperinfo {
    display: flex;
    border-right: 2px solid #4A5568;
    width: fit-content;
    padding-right: 20px;
}

.googlemapimage {
    width: 100%;
    height: 600px;
}

li {
    margin-top: 20px;
}

.gifimage {
    height: 800px;
}

@media only screen and (max-width: 768px) {
    .firebaseimage {
        width: 100%;
    }

    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }

    .heading {
        font-size: 35px;
    }

    .headingcontent {
        font-size: 30px;
    }

    .blogcontent {
        font-size: 18px;
    }

    .gifimage {
        width: 100%;
    }

    .googlemapimage {
        height: 450px;
    }
}

@media only screen and (max-width: 425px) {
    .googlemapimage {
        height: 350px;
    }

    .blogcontent {
        font-size: 16px;
    }
}

@media only screen and (max-width: 320px) {

    .heading {
        font-size: 30px;
    }

    .listcontent {
        font-size: 16px;
    }

    .headingcontent {
        font-size: 26px;
    }

    .blogcontent {
        font-size: 14px;
    }
}