.maindiv {
    margin-top: 60px;
}

.technologybtn:hover,
.technologybtn:active,
.technologybtn:focus,
.technologybtn:focus-within {
    color: #0B618E !important;
    background-color: transparent !important;
}

.technologydiv {
    background-color: #E1EFF6;
    margin-top: 60px;
}

.langdiv {
    background-color: #FFFFFF;
    border-radius: 15px;
    height: 170px;
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.technologybtn {
    color: #4A5568 !important;
    background-color: transparent !important;
    font-size: 22px;
    font-weight: 600;
    position: relative;
}

.technologybtn::after,
.technologybtn:focus-within::after {
    content: '';
    position: absolute;
    bottom: -5px;
    width: 113px;
    height: 3px;
    background-color: transparent;
    transition: background-color 0.3s;
    left: 50%;
    transform: translateX(-50%);
}

.technologybtn.active::after {
    background-color: #0B618E;
}

.technologybtn:active::after,
.technologybtn:focus::after,
.technologybtn:focus-within::after {
    background-color: #0B618E;
}

.langmenu {
    margin-top: 80px;
}

.langmenudiv::-webkit-scrollbar {
    display: none;
}

.langmenudiv {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.imgitem {
    height: 80px;
    width: 100%;
}


@media only screen and (max-width: 1140px) {
    .technologybtn {
        font-size: 18px;
    }

    .langmenu {
        margin-top: 50px;
    }

    .langdiv {
        width: 130px;
        height: 130px;
    }

    .imgitem {
        height: 55px;
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .langdiv {
        width: 130px;
        height: 130px;
    }

    .maindiv {
        margin-top: 40px;
    }

    .technologydiv {
        margin-top: 40px;
    }

    .imgitem {
        height: 55px;
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .technologybtn {
        font-size: 16px;
    }

    .imgitem {
        height: 55px;
        width: 100%;
    }
}

@media only screen and (max-width: 830px) {
    .technologybtn {
        font-size: 14px;
    }

    .langdiv {
        width: 110px;
        height: 110px;
    }

    .imgitem {
        height: 55px;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .technologybtn {
        font-size: 14px;
    }

    .langmenu {
        margin-top: 0;
    }

    .langmenuwidth {
        width: 800px;
    }

    .langmenu {
        width: 800px;
    }

    .langdiv {
        width: 130px;
        height: 130px;
    }

    .langmenudiv {
        overflow: auto;
    }

    .imgitem {
        height: 60px;
        width: 100%;
    }
}


@media only screen and (max-width: 425px) {

    .langdiv {
        height: 110px;
        width: 110px;
    }

    .langmenu {
        width: 715px;
    }

    .maindiv {
        margin-top: 30px;
    }

    .langmenuwidth {
        width: 800px;
    }

    .technologydiv {
        margin-top: 30px;
    }

    .imgitem {
        height: 60px;
        width: 100%;
    }
}