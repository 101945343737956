.modalheading {
    font-size: 46px;
    font-weight: 800;
}

.closebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 1;
    box-shadow: 0px 4px 34px 0px #0B618E1A;
    background-color: #FFFFFF;
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

/* .submitbtn:hover, */
.submitbtn {
    background-image: linear-gradient(#6EB7D7, #0B618E);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border: none;
    padding: 10px;
    width: 20%;
}

.inputfield:focus {
    border: 1px solid #B8B6B6;
    box-shadow: 0px 14px 47px 0px #0B618E1A;
}

.inputfield {
    background-color: #FFFFFF;
    box-shadow: 0px 14px 47px 0px #0B618E1A;
    color: #A8A8A8;
    font-size: 15px;
    font-weight: 400;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #B8B6B6;
}

.inputwidth {
    width: 50%;
    height: fit-content;
}

.modalcontent {
    font-size: 16px;
    font-weight: 400;
}

@media only screen and (max-width: 1023px) {
    .modalheading {
        font-size: 35px;
    }
}

@media only screen and (max-width: 989px) {
    .modalheading {
        font-size: 30px;
    }

    .modalcontent {
        font-size: 14px;
    }

    .inputfield {
        padding-bottom: 8px;
        padding-top: 8px;
    }

    .inputwidth {
        width: 100%;
    }

    .submitbtn {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

@media only screen and (max-width: 768px) {
    .togglesidebar {
        width: 30%;
    }

    .submitbtn {
        width: 40%;
    }

    .closebtn {
        top: 12px;
        right: 12px;
        width: 40px;
        height: 40px;
        box-shadow: none;
    }
}

@media only screen and (max-width: 425px) {
    .inputfield {
        font-size: 14px;
    }

    .submitbtn {
        font-size: 14px;
        padding: 6px;
    }

    .modalheading {
        font-size: 25px;
    }

}

@media only screen and (max-width: 375px) {
    .modalheading {
        font-size: 24px;
    }
}