.maindiv {
    margin-top: 70px;
    margin-bottom: 70px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
    margin-bottom: 60px;
}

.subheading {
    color: #1A202C;
    font-size: 22px;
    font-weight: 700;
    width: 307px;
    text-align: center;
}

.subcontent {
    color: #4A5568;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 307px;
    text-align: center;
}

.uiuxbox1 {
    position: absolute;
    top: 0;
    left: 30%;
}

.uiuxbox2 {
    position: absolute;
    top: 0;
    right: 29%;
}

.uiuxbox4 {
    position: absolute;
    bottom: 0;
    left: 17%;
}

.uiuxbox3 {
    position: absolute;
    bottom: 0;
    right: 41%;
}

.uiuxbox5 {
    position: absolute;
    bottom: 0;
    right: 15%;
}

.uiuxflowchart {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.flowimage {
    width: 100%;
}

@media only screen and (max-width: 1800px) {

    .uiuxbox1 {
        left: 29%;
    }

    .uiuxbox2 {
        right: 27%;
    }

    .uiuxbox4 {
        left: 15%;
    }

    .uiuxbox3 {
        right: 40%;
    }

    .uiuxbox5 {
        right: 13%;
    }
}

@media only screen and (max-width: 1700px) {

    .uiuxbox1 {
        left: 28%;
    }

    .uiuxbox2 {
        right: 26%;
    }

    .uiuxbox4 {
        left: 13%;
    }

    .uiuxbox3 {
        right: 39%;
    }

    .uiuxbox5 {
        right: 10%;
    }
}

@media only screen and (max-width: 1600px) {
    .box1 {
        left: 32%;
    }

    .box2 {
        right: 11%;
    }

    .box3 {
        right: 30%;
    }

    .box4 {
        left: 13%;
    }

    .uiuxbox1 {
        left: 26%;
    }

    .uiuxbox2 {
        right: 24%;
    }

    .uiuxbox4 {
        left: 11%;
    }

    .uiuxbox3 {
        right: 38%;
    }

    .uiuxbox5 {
        right: 8%;
    }
}

@media only screen and (max-width: 1500px) {

    .uiuxbox1 {
        left: 25%;
    }

    .uiuxbox2 {
        right: 22%;
    }

    .uiuxbox4 {
        left: 8%;
    }

    .uiuxbox3 {
        right: 38%;
    }

    .uiuxbox5 {
        right: 6%;
    }
}

@media only screen and (max-width: 1400px) {

    .uiuxbox1 {
        left: 24%;
    }

    .uiuxbox2 {
        right: 21%;
    }

    .uiuxbox4 {
        left: 6%;
    }

    .uiuxbox3 {
        right: 38%;
    }

    .uiuxbox5 {
        right: 4%;
    }
}

@media only screen and (max-width: 1440px) {
    .flowimage {
        width: 80%;
    }

    .scroll {
        overflow: auto;
    }

    .uiuxbox1 {
        left: 27%;
    }

    .uiuxbox2 {
        right: 25%;
    }

    .uiuxbox4 {
        left: 13%;
    }

    .uiuxbox3 {
        right: 38%;
    }

    .uiuxbox5 {
        right: 11%;
    }
}



@media only screen and (max-width: 1350px) {

    .uiuxbox1 {
        left: 25%;
    }

    .uiuxbox2 {
        right: 23%;
    }

    .uiuxbox4 {
        left: 10%;
    }

    .uiuxbox3 {
        right: 38%;
    }

    .uiuxbox5 {
        right: 8%;
    }
}


@media only screen and (max-width: 1200px) {

    .uiuxbox1 {
        left: 21%;
    }

    .uiuxbox2 {
        right: 20%;
    }

    .uiuxbox4 {
        left: 5%;
    }

    .uiuxbox3 {
        right: 35%;
    }

    .uiuxbox5 {
        right: 3%;
    }
}

@media only screen and (max-width: 1024px) {
    .heading {
        font-size: 35px;
    }

    .subcontent {
        font-size: 14px;
    }

    .subheading {
        font-size: 18px;
    }

    .content {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .uiuxbox1 {
        left: 20%;
    }

    .uiuxbox2 {
        right: 18%;
        top: -2%;
    }

    .uiuxbox4 {
        left: 3%;
    }

    .uiuxbox3 {
        right: 34%;
    }

    .uiuxbox5 {
        right: 1%;
    }
}

@media only screen and (max-width: 900px) {
    .subcontent {
        display: none;
    }

    .subheading {
        font-size: 16px;
    }

    .uiuxbox1 {
        left: 17%;
    }

    .uiuxbox2 {
        right: 16%;
    }

    .uiuxbox4 {
        left: 0%;
    }

    .uiuxbox3 {
        right: 31%;
    }

    .uiuxbox5 {
        right: 0%;
    }

    .uiuxflowchart {
        height: 100%;
    }
}


@media only screen and (max-width: 768px) {
    .heading {
        font-size: 30px;
    }

    .maindiv {
        margin-top: 30px;
        margin-bottom: 80px;
    }

    .content {
        width: 80%;
        font-size: 18px;
    }

    .subheading {
        font-size: 14px;
        width: fit-content;
        line-height: 22px;
    }

    .uiuxbox1 {
        left: 25%;
    }

    .uiuxbox2 {
        right: 24%;
    }

    .uiuxbox4 {
        left: 10%;
    }

    .uiuxbox3 {
        right: 43%;
    }

    .uiuxbox5 {
        right: 8%;
    }

    .uiuxbox1,
    .uiuxbox2 {
        top: -8%;
    }

    .uiuxbox3,
    .uiuxbox4,
    .uiuxbox5 {
        top: 90%;
    }
}

@media only screen and (max-width: 576px) {
    .subheading {
        font-size: 12px;
        width: fit-content;
    }
    .uiuxbox1,
    .uiuxbox2 {
        top: -10%;
    }
    .subheading{
        line-height: 18px;
    }

}

@media only screen and (max-width: 425px) {
    .flowimage {
        width: 90%;
    }

    .content {
        width: 100%;
        font-size: 16px;
        margin-top: 15px;
    }

    .uiuxbox1 {
        left: 19%;
    }

    .uiuxbox2 {
        right: 18%;
    }

    .uiuxbox4 {
        left: 2%;
    }

    .uiuxbox3 {
        right: 39%;
    }

    .uiuxbox5 {
        right: 2%;
    }
}

@media only screen and (max-width: 375px) {
    .heading {
        font-size: 28px;
    }

    .subheading {
        font-size: 10px;
        line-height: 14px;
    }

}

@media only screen and (max-width: 320px) {
    .heading {
        font-size: 26px;
    }
    .uiuxbox1 {
        left: 21%;
    }

    .uiuxbox2 {
        right: 20%;
    }

    .uiuxbox4 {
        left: 2%;
    }

    .uiuxbox3 {
        right: 41%;
    }

    .uiuxbox5 {
        right: 2%;
    }
    .subheading {
        font-size: 8px;
    }

}