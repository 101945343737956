.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 70px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
    width: 60%;
    margin-top: 50px;
}

.sevicediv {
    padding: 35px;
    background-color: #FDFDFF;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    overflow: hidden;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
}

.boldfont {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 20px;
}

.contentfont {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    opacity: 0.7;
    margin-top: 20px;
}

.borderrow {
    margin: 0 -4px -4px 0;
}

@media only screen and (max-width: 1300px) {
    .sevicediv {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1100px) {
    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
    }

    .contentfont {
        margin-top: 15px;
    }

    .boldfont {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 1024px) {
    .sevicediv {
        padding-top: 0;
        padding: 18px;
    }

    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 50px;
    }
}

@media only screen and (max-width: 990px) {
    .sevicediv {
        padding-top: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        font-size: 18px;
        width: 100%;
        margin-top: 20px;
        line-height: 30px;
    }

    .maindiv {
        padding-left: 40px;
       padding-right: 40px;
       padding-top: 40px;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
       padding-left: 10px;
       padding-right: 10px;
       padding-top: 10px;
    }

    .content {
        font-size: 16px;
        line-height: 28px;
    }

    .sevicediv {
        padding-top: 18px;
    }

    .boldfont {
        margin-top: 10px;
        font-size: 16px;
    }

    .contentfont {
        font-size: 14px;
        margin-top: 10px;
    }
}