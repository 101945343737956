.modalheading {
    font-size: 46px;
    font-weight: 800;
}

.modalcontent {
    color: #4A5568;
    font-size: 24px;
    font-weight: 400;
}

.closebtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 1;
    box-shadow: 0px 4px 34px 0px #0B618E1A;
    background-color: #FFFFFF;
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

/* .sendbtn:hover, */
.sendbtn {
    background-image: linear-gradient(#6EB7D7, #0B618E);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border: none;
    padding: 10px;
    width: 30%;
}

.inputfield:focus {
    border: none !important;
    background-color: #EFEEEE !important;
}

.inputfield {
    border: none;
    background-color: #EFEEEE;
    color: #A8A8A8;
    font-size: 16px;
    font-weight: 400;
    padding-top: 15px;
    padding-bottom: 15px;
}

.inputwidth {
    width: 50%;
}

@media only screen and (max-width: 1023px) {
    .modalheading {
        font-size: 35px;
    }

    .modalcontent {
        font-size: 18px;
    }
}

@media only screen and (max-width: 989px) {
    .modalheading {
        font-size: 30px;
    }

    .modalcontent {
        font-size: 16px;
    }

    .inputfield {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .inputwidth {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .togglesidebar {
        width: 35%;
    }

    .sendbtn {
        width: 40%;
    }
}

@media only screen and (max-width: 576px) {
    .closebtn {
        top: 12px;
        right: 12px;
        width: 40px;
        height: 40px;
        box-shadow: none;
    }
}

@media only screen and (max-width: 425px) {
    .inputfield {
        font-size: 14px;
    }

    .modalcontent {
        font-size: 14px;
    }

    .sendbtn {
        font-size: 14px;
        padding: 6px;
        width: 150px;
    }

    .modalheading {
        font-size: 25px;
    }
}

@media only screen and (max-width: 375px) {
    .modalcontent {
        font-size: 12px;
    }

    .modalheading {
        font-size: 24px;
    }
}