@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.content {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    padding-top: 80px;
    /* padding-bottom: 65px; */
    /* padding-left: 70px; */
    /* padding-right: 70px; */
}

.heading {
    color: #1A202C;
    font-size: 53px;
    font-weight: 800;
    text-align: center;
}

.blogcontent {
    color: #4A5568;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    width: 60%;
}

.readmorebtn {
    font-size: 16px;
    font-weight: 500;
    background-image: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 30%;
    padding-left: 0 !important;
}

.content {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    height: 40%;
}

.contentheading {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    height: 30%;
}

.cardimage {
    height: 50%;
    border-radius: 10px;
}

.card {
    height: 438px;
    padding: 15px;
    border: none;
}

.backcolor {
    box-shadow: 0px 20px 90px 0px #07003B1F;
    background-color: #FDFDFF;
    border-radius: 10px;
}


@media only screen and (max-width:1024px) {
    .heading {
        font-size: 40px;
    }
}

@media only screen and (max-width:768px) {
    .blogcontent {
        width: 80%;
        font-size: 16px;
    }
}

@media only screen and (max-width:425px) {

    .heading {
        font-size: 35px;
    }

    .contentheading {
        font-size: 16px;
    }

    .content {
        font-size: 14px;
    }

    .readmorebtn {
        font-size: 14px;
    }

    .card {
        height: 390px;
    }
}