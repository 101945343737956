@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.MainDiv {
    margin: 0px 47px;
}

@media only screen and (max-width:768px) {
    .MainDiv {
        margin: 0px 60px;
    }
}

@media only screen and (max-width:425px) {
    .MainDiv {
        margin: 0px 46px;
    }
}

@media only screen and (max-width:320px) {
    .MainDiv {
        margin: 0px 30px;
    }
}


/* .EditText {
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
} */