.maindiv {
    padding-top: 80px
}

.rowpadding {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.software {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
}

.development {
    background: linear-gradient(#6EB7D7, #0B618E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.company {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
    line-height: 45px;
}

.spancontent {
    color: #4A5568;
    font-size: 20px;
    font-weight: 800;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.flutterimage {
    height: 462px;
    width: 100%;
}

.fullstackimage {
    max-width: 100%;
    height: auto;
}

@media only screen and (max-width: 1024px) {
    .rowpadding {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 35px;
    }

    .content {
        font-size: 17px;
        line-height: 36px;
    }

    .heading {
        font-size: 35px;
    }
}

@media only screen and (max-width: 768px) {
    .rowpadding {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }

    .company,
    .software,
    .development {
        font-size: 30px;
    }

    .content {
        font-size: 15px;
        line-height: 35px;
    }

    .heading {
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px) {
    .rowpadding {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        font-size: 14px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 375px) {

    .heading,
    .company,
    .software,
    .development {
        font-size: 28px;
    }

}

@media only screen and (max-width: 320px) {

    .heading,
    .company,
    .software,
    .development {
        font-size: 26px;
    }

}