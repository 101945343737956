@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.SatisfiedClients {
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.SatisfiedClientsBgColor {
    width: 100%;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
    padding: 27px 07px 22px 09px;
}

.ImgSet {
    width: 190px;
    /* height: 80px; */
    padding: 20px 0px;
}

.ImgContainer {
    margin: 0px 50px;
}