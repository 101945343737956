.maindiv {
    padding: 70px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    position: relative;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 185px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
    width: 60%;
    margin-top: 50px;
}

.sevicediv {
    padding: 35px;
    background-color: #FDFDFF;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    overflow: hidden;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
}

.boldfont {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 20px;
}

.contentfont {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    opacity: 0.7;
    margin-top: 20px;
}

.borderrow {
    margin: 0 -4px -4px 0;
}

@media only screen and (max-width: 1300px) {
    .sevicediv {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1100px) {
    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
    }

    .contentfont {
        margin-top: 15px;
    }

    .boldfont {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 1024px) {
    .sevicediv {
        padding-top: 0;
        padding: 18px;
    }

    .heading {
        font-size: 35px;
    }

    .maindiv {
        padding: 50px;
    }

    .content {
        width: 80%;
        font-size: 18px;
        line-height: 32px;
    }
}

@media only screen and (max-width: 990px) {
    .sevicediv {
        padding-top: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        font-size: 18px;
        width: 80%;
        margin-top: 20px;
        line-height: 30px;
    }

    .heading {
        font-size: 30px;
    }

    .maindiv {
        padding: 40px;
    }
}


@media only screen and (max-width: 425px) {
    .maindiv {
        padding: 10px;
    }

    .heading {
        font-size: 30px;
    }

    .sevicediv {
        padding-top: 18px;
    }

    .boldfont {
        margin-top: 10px;
        font-size: 16px;
    }

    .contentfont {
        font-size: 14px;
        margin-top: 10px;
    }

    .content {
        width: 100%;
        font-size: 16px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 375px) {
    .heading {
        font-size: 28px;
    }
}