.maindiv {
    padding-top: 80px;
}

.rowpadding {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.software {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
}

.development {
    background: linear-gradient(#6EB7D7, #0B618E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.company {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.and {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
}

.container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.contentidentifybb {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
    width: 60%;
    line-height: 30px;
}

.timeline {
    position: relative;
    padding-left: 0;
}

.timelineinfoleft {
    width: 50%;
    padding-right: 3rem;
    text-align: end;
}

.timelinecontentright {
    width: 50%;
    padding-left: 2rem;
    text-align: start;
}

.timelineinforight {
    width: 50%;
    padding-left: 2rem;
    text-align: start;
}

.timelinecontentleft {
    width: 50%;
    padding-right: 3rem;
    text-align: end;
}

.timelinemarker:after {
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    border: 2px solid #FFFFFF;
    top: 0px;
    left: -3px;
    height: 20px;
    width: 20px;
    box-shadow: 0px 4px 14px 0px #68B1D2;
}

.timelinemarker:after,
.timelinemarker:before {
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.timelinemarker {
    position: absolute;
    left: 50%;
    margin-left: -9.5px;
}

.timeline:before {
    position: absolute;
    content: "";
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    background: #1589F1;
    width: 1px;
    height: 100%;
}

.storyheading {
    color: #000000;
    font-size: 26px;
    font-weight: 600;
}

.storyheading3 {
    color: #000000;
    font-size: 26px;
    margin-top: 160px;
    font-weight: 600;
}

.contentwhoareyou {
    color: #4A5568;
    font-size: 20px;
    font-weight: 600;
    line-height: 47px;
}

.aboutusimage2 {
    width: 650px;
    height: 750px;
}

.getquotebtn:hover,
.getquotebtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    border: none;
}

.boldfont {
    color: #1A202C;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
}

.contentfont {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-top: 20px;
}

.roundspringimg {
    position: absolute;
}

.sevicediv:hover {
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);

}

.sevicediv:hover .bottomsvg {
    background: transparent;
    border: 2px solid #FFFFFF
}

.sevicediv:hover .boldfont {
    color: #FFFFFF;
}

.sevicediv:hover .contentfont {
    color: #B7CAD3;
}

.sevicediv {
    padding: 20px;
    padding-top: 40px;
    height: 100%;
    background-color: #FDFDFF;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    border-radius: 12px;
}

.bottomsvg {
    height: 100px;
    width: 100px;
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.springimage {
    position: absolute;
    bottom: -285px;
    left: -75px;
    z-index: -1;
}

.boxsection {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 30px;
    padding-top: 50px;
}

.svgimagebb {
    height: 64px;
    width: 64px;
}

.aboutusimage {
    max-width: 100%;
    height: auto;
}

@media only screen and (max-width: 1650px) {
    .springimage {
        bottom: -300px;
    }
}

@media only screen and (max-width: 1600px) {
    .springimage {
        bottom: -330px;
    }
}

@media only screen and (max-width: 1440px) {
    .springimage {
        bottom: -350px;
    }

    .aboutusimage2 {
        height: 100%;
        width: 100%;
    }

    .content {
        width: 80%;
    }
}

@media only screen and (max-width: 1350px) {
    .contentwhoareyou {
        font-size: 18px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 1250px) {
    .springimage {
        bottom: -380px;
    }
}

@media only screen and (max-width: 1024px) {

    .rowpadding,
    .boxsection {
        padding-left: 40px;
        padding-top: 40px;
        padding-right: 40px;
    }

    .sevicediv {
        padding-top: 20px;
    }

    .timelinecontentleft,
    .timelinecontentright,
    .timelineinfoleft,
    .timelineinforight {
        width: 100%;
    }

    .storyheading {
        font-size: 22px;
    }

    .springimage {
        display: none;
    }

    .content {
        width: 90%;
    }

    .contentwhoareyou {
        font-size: 16px;
        line-height: 32px;
    }

    .contentidentifybb {
        font-size: 16px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 40px;
    }

    .boldfont {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 768px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 30px;
    }

    .content {
        font-size: 16px;
        width: 100%;
    }
    .boxsection {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width:600px) {
    .contentidentifybb {
        font-size: 14px;
    }

    .timelineinfoleft {
        padding-left: 3rem;
    }

    .timelinecontentright {
        padding-left: 0;

    }

    .timelinecontentleft {
        padding-right: 1rem;

    }

    .storyheading {
        font-size: 18px;
    }
}

@media only screen and (max-width: 425px) {

    .rowpadding,
    .boxsection {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
    }

    .contentfont,
    .boldfont {
        margin-top: 10px;
    }

    .sevicediv {
        padding: 10px;
    }

    .contentidentifybb {
        font-size: 12px;
    }

    .storyheading {
        font-size: 14px;
    }

    .svgimagebb {
        height: 50px;
        width: 50px;
    }
}