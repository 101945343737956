.maindiv {
    margin-top: 70px;
    padding-left: 70px;
    padding-right: 70px;
    margin-bottom: 90px;
}

.custom_color {
    color: rgb(107 114 128);
}

.custom_img {
    width: 100%;
    height: 300px;
}

.custom_set {
    margin-bottom: 60px;
}

@media only screen and (max-width: 2560px) {
    .custom_img {
        width: 100%;
        height: 300px;
    }

    .custom_set {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 1440px) {
    .custom_img {
        width: 100%;
        height: 300px;
    }

    .custom_set {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 1024px) {

    .maindiv {
        margin-top: 40px;
    }

    .custom_set {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 1100px) {}

@media only screen and (max-width: 990px) {
    .custom_img {
        width: 100%;
        height: 200px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
        margin-top: 70px;
    }

    .custom_set {
        margin-bottom: 35px;
    }
}

@media only screen and (max-width: 576px) {

    .maindiv {
        margin-top: 50px;
        margin-bottom: 40px;
    }

    .custom_img {
        width: 100%;
        height: 250px;
    }

    .custom_set {
        margin-bottom: 35px;
    }

}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 30px;
        margin-bottom: 40px;
    }

    .custom_img {
        width: 100%;
        height: 200px;
    }

    .custom_set {
        margin-bottom: 35px;
    }

}