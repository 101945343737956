.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
    margin-bottom: 60px;
}

.subheading {
    color: #1A202C;
    font-size: 22px;
    font-weight: 700;
    width: 307px;
    text-align: center;
}

.subcontent {
    color: #4A5568;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 307px;
    text-align: center;
}

.step1 {
    height: 321px;
    border: 1px solid #AFEBD6;
    background-color: #C4F5E4;
    padding: 30px;
    border-radius: 12px;
}

.step2 {
    height: 321px;
    border: 1px solid #FFD9BF;
    background-color: #FFE5D3;
    padding: 30px;
    border-radius: 12px;
}

.step3 {
    height: 321px;
    background-color: #D6DDF9;
    border: 1px solid #C9D2F8;
    padding: 30px;
    border-radius: 12px;
}

.boxheading {
    color: #1A202C;
    height: 40%;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
}

.boxcontent {
    height: 60%;
    color: #4A5568;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
}

.linegroup {
    width: 100%;
    height: 64px;
}

@media only screen and (max-width: 1440px) {

    .step1,
    .step2,
    .step3 {
        padding: 20px;
    }

    .boxheading {
        font-size: 26px;
    }
}

@media only screen and (max-width: 1024px) {

    .step1,
    .step2,
    .step3 {
        padding: 16px;
    }

    .boxheading {
        font-size: 24px;
    }

    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
    }

    .boxcontent {
        font-size: 15px;
    }

    .content {
        width: 80%;
        font-size: 20px;
        margin-bottom: 40px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 900px) {
    .boxheading {
        font-size: 22px;
    }

    .boxcontent {
        font-size: 14px;
    }
}

@media only screen and (max-width: 576px) {

    .step1,
    .step2,
    .step3 {
        height: 250px;
    }

    .boxcontent {
        font-size: 16px;
    }

    .boxheading {
        font-size: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }

    .boxheading,
    .boxcontent {
        height: auto;
    }

    .content {
        line-height: 25px;
        font-size: 16px;
        margin-bottom: 20px;
        width: 100%;
    }

    .step1,
    .step2,
    .step3 {
        height: 100%;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        margin-bottom: 20px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .boxcontent {
        font-size: 15px;
    }
}