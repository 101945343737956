.maindiv {
    padding: 70px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    line-height: 35px;
    width: 60%;
    margin-top: 50px;
}

.boxleft {
    display: flex;
    background-color: #FDFDFF;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    margin-left: 80px;
    padding-left: 80px;
    position: relative;
}

.boxright {
    display: flex;
    background-color: #FDFDFF;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    padding: 30px;
    border-radius: 12px;
    width: 80%;
    margin-right: 80px;
    padding-right: 80px;
    position: relative;
}

.roundleft {
    height: 113px;
    width: 113px;
    border-radius: 50%;
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.roundright {
    height: 113px;
    width: 113px;
    border-radius: 50%;
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -8%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.boxheading {
    color: #1A202C;
    font-size: 24px;
    font-weight: 700;
}

.boxcontent {
    color: #4A5568;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;

}

@media only screen and (max-width: 1600px) {
    .roundright {
        right: -11%;
    }
}

@media only screen and (max-width: 1440px) {
    .roundright {
        right: -10%;
    }
}

@media only screen and (max-width: 1350px) {
    .roundright {
        right: -13%;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        padding: 50px;
    }

    .content {
        font-size: 18px;
        width: 80%;
        line-height: 32px;
        margin-top: 30px;
    }

    .boxleft,
    .boxright {
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .boxheading {
        font-size: 22px;
    }

    .boxcontent {
        font-size: 16px;
    }

    .roundleft,
    .roundright {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding: 40px
    }

    .content {
        font-size: 16px;
        width: 100%;
        margin-top: 20px;
    }

    .boxleft {
        margin-left: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .roundright {
        left: 0;
    }

    .boxright {
        margin-left: 50px;
        margin-right: 0;
        padding-left: 80px;
        padding-right: 0;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .boxheading {
        font-size: 20px;
        text-align: start;
    }

    .boxcontent {
        font-size: 14px;
        text-align: start;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding: 10px;
        padding-top: 30px;
    }

    .boxleft,
    .boxright {
        padding-left: 55px;
    }

    .roundleft,
    .roundright {
        height: 90px;
        width: 90px;
    }
}

@media only screen and (max-width: 375px) {
    .content {
        font-size: 14px;
        line-height: 26px;
    }

    .boxleft,
    .boxright {
        padding-left: 50px;
        padding-right: 10px;
        margin-left: 45px;
    }
}