@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.squarecontent {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    margin-top: 60px;
    padding-left: 70px;
    padding-right: 70px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    margin-left: 20px;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 185px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.content {
    color: var(--Gray-gray-700, #4A5568);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 47px;
}

.contactusbtn:hover,
.contactusbtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #FFF !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    padding: 10px 31px;
    /* margin-top: 50px; */
    border: none;
}

.round {
    background-color: #FFF;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.detailexperience {
    display: flex;
    justify-content: space-between;
    background-color: #0B618E;
    border-radius: 10px;
    padding: 40px;
    width: 60%;
    text-align: end;
    position: absolute;
    top: 40rem;
    right: 0;
}

.detailcontent {
    color: #F2F2F2;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
}

.number {
    color: #F2F2F2;
    font-size: 37px;
    font-style: normal;
    font-weight: 500;
}

.roundimage {
    height: 85px;
    width: 85px;
    background: linear-gradient(225deg, #68B1D2 0%, #0B618E 100%);
    border-radius: 50px;
    position: absolute;
    top: 2%;
    left: -1%;
    z-index: -1;
}

.squarimage {
    background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%);
    height: 144px;
    width: 151px;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 40%;
}

.squarecontent {
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 500;
}

.experienceimage {
    height: 750px;
    width: 650px;
}

@media only screen and (max-width: 2000px) {
    .detailexperience {
        top: 36rem;
    }

    .maindiv {
        margin-bottom: 80px;
    }
}

@media only screen and (max-width: 1800px) {
    .detailexperience {
        top: 36rem;
    }

    .maindiv {
        margin-bottom: 130px;
    }
}

@media only screen and (max-width: 1700px) {
    .detailexperience {
        top: 32rem;
    }

    .roundimage {
        top: 3%;
    }

    .maindiv {
        margin-bottom: 120px;
    }
}

@media only screen and (max-width: 1500px) {
    .detailexperience {
        top: 26rem;
    }

    .contactusbtn {
        margin-top: 20px;
    }

    .maindiv {
        margin-bottom: 80px;
    }
}


@media only screen and (max-width: 1450px) {
    .detailexperience {
        top: 26rem;
    }

    .contactusbtn {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1400px) {
    .detailexperience {
        position: static !important;
        width: 100%;
    }

    .squarimage {
        bottom: 10px;
        left: 0%;
        height: 110px;
        width: 110px;
        padding: 12px;
    }

    .number {
        font-size: 28px;
    }

    .squarecontent {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 40px;
    }

    .content {
        line-height: 40px;
    }

    .heading {
        font-size: 35px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:820px) {
    .round {
        height: 70px;
        width: 70px;
    }

    .detailcontent {
        font-size: 12px;
        line-height: 35px;
    }

    .number {
        font-size: 20px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }

    .heading {
        font-size: 30px;
    }

    .content {
        line-height: 35px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:630px) {
    .heading {
        font-size: 35px;
    }

    .content {
        line-height: 35px;
    }

    .detailexperience {
        padding: 20px;
        margin-top: 20px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:600px) {
    .squarimage {
        height: 100px;
        width: 100px;
    }

    .round {
        height: 65px;
        width: 65px;
    }

    .content {
        line-height: 30px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:550px) {
    .detailcontent {
        line-height: 20px;
    }

    .round {
        height: 60px;
        width: 60px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 20px;
    }

    .heading {
        font-size: 30px;
    }

    .content {
        font-size: 16px;
        line-height: 28px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:375px) {
    .detailcontent {
        line-height: 20px;
    }

    .heading {
        font-size: 28px;
    }

    .maindiv {
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:320px) {
    .round {
        height: 40px;
        width: 40px;
    }

    .heading {
        font-size: 26px;
    }

    .detailexperience {
        padding-left: 0;
        padding-right: 0;
    }

    .detailcontent {
        font-size: 12px;
        line-height: 25px;
    }

    .number {
        font-size: 15px;
    }

    .maindiv {
        margin-bottom: 0px;
    }

}