.maindiv {
    padding-top: 80px;
    position: relative;
}

.springimage {
    position: absolute;
}

@media only screen and (max-width: 1024px) {
    .springimage {
        display: none;
    }
}