.maindiv {
    padding: 70px;
    background-color: #F8F9FA;
}

.heading {
    color: #1A202C;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 50%;
    margin-bottom: 30px;
}

.contactinfo {
    position: absolute;
    top: 15%;
    left: 10%;
    z-index: 1;
    width: 70%;
}

.form {
    width: 80%;
}

.inputfield {
    border: none;
    border-bottom: 1px solid #4A556880;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    color: #4A5568;
    font-size: 20px;
    font-weight: 500;
}

.submitbtn {
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
}

.button {
    width: 20%;
    text-align: center;
    padding-bottom: 70px;
}

@media only screen and (max-width: 1024px) {
    .heading {
        font-size: 32px;
    }

    .button {
        padding-bottom: 50px;
    }

    .form {
        width: 90%;
    }

    .content {
        width: 80%;
        font-size: 20px;
    }

    .maindiv {
        padding: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .heading {
        font-size: 26px;
    }

    .content {
        font-size: 18px;
        width: 80%;
    }

    .inputfield {
        font-size: 18px;
    }

    .maindiv {
        padding: 40px;
    }

    .button {
        padding-bottom: 40px;
        width: 40%;
    }
}

@media only screen and (max-width: 425px) {
    .heading {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .content {
        width: 100%;
        font-size: 16px;
    }

    .inputfield {
        font-size: 16px;
    }

    .maindiv {
        padding: 10px;
    }

    .submitbtn {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {
    .heading{
        font-size: 22px;
    }
}