@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.contentfont {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    padding-top: 60px;
    padding-left: 70px;
    padding-right: 70px;
    /* padding-bottom: 0px; */
}

.sevicediv {
    position: relative;
    padding: 20px;
    padding-top: 85px;
    /* height: 380px; */
    background-color: #FDFDFF;
    filter: drop-shadow(0px 50px 90px rgba(7, 0, 59, 0.12));
    border-radius: 12px;
    /* overflow: hidden; */
}

.CardImg {
    height: 300px;
    width: 100%;
}

.gradientborder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7px;
    background: linear-gradient(90deg, #68B1D2 1.81%, #0B618E 100%);
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}

.boldfont {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 20px;
}

.contentfont {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    opacity: 0.7;
    margin-top: 20px;
}

.contentfont,
.boldfont {
    padding-left: 12px;
    padding-right: 12px;
}

.oversvg {
    position: absolute;
    top: 14px;
    left: 12px;
}

.round {
    background: linear-gradient(225deg, #68B1D2 0%, #0B618E 100%);
    height: 85px;
    width: 85px;
    border-radius: 50px;
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (max-width: 1300px) {
    .sevicediv {
        padding-top: 30px;
    }
}

@media only screen and (max-width: 1100px) {
    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width: 1024px) {
    .sevicediv {
        padding-top: 0;
    }

    .maindiv {
        padding: 40px;
    }

    .round {
        height: 70px;
        width: 70px;
    }
}

@media only screen and (max-width: 990px) {
    .sevicediv {
        padding-top: 50px;
    }

    .round {
        top: -20px;
        right: -20px;
    }
}

@media only screen and (max-width: 768px) {

    .round {
        display: none;
    }

    .sevicediv {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
        padding: 20px;
    }

    .sevicediv {
        height: 100%;
    }

    .CardImg {
        height: 240px;
    }
}