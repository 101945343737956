.maindiv {
    border: 1px solid #737373;
    border-radius: 20px;
    padding: 50px;
}

.contactheading {
    font-size: 34px;
    font-weight: 500;
    color: #1A202C;
    text-align: center;
}

.subcontentheading {
    color: #4A5568;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
}

.inputfield {
    border: none;
    border-radius: 0;
    border-radius: 5px;
    color: #A8A8A8;
    font-size: 16px;
    font-weight: 400;
    background-color: #EFEEEE;
}

.submitbtn {
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.button {
    width: 80%;
}

@media only screen and (max-width:1024px) {
    .contactheading {
        font-size: 28px;
    }

    .maindiv {
        padding: 30px;
    }

    .subcontentheading {
        font-size: 16px;
    }

    .inputfield {
        font-size: 15px;
    }
}

@media only screen and (max-width:768px) {

    .contactheading {
        font-size: 20px;
    }

    .maindiv {
        padding: 20px;
    }

    .subcontentheading {
        font-size: 14px;
    }

    .inputfield {
        font-size: 15px;
    }

    .submitbtn {
        font-size: 14px;
    }
}

@media only screen and (max-width:425px) {
    .contactheading {
        font-size: 18PX;
    }
}

@media only screen and (max-width:375px) {
    .maindiv {
        padding: 12px;
    }
}