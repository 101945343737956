.maindiv {
    height: 80px;
    position: fixed;
    width: 100%;
    z-index: 10;
}

.header {
    background: #FFF;
    box-shadow: 0px 4px 53px 0px rgba(110, 183, 215, 0.20);
    padding-left: 60px;
    padding-right: 60px;
}

.dropbtn:focus {
    box-shadow: none !important;
}

.headermenu {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #000 !important;
    margin-left: 50px;
}

.headermenutoggle {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    color: #000 !important;
    padding-left: 0;
}

.getquotebtn:hover,
.getquotebtn {
    background-image: linear-gradient(#6EB7D7, #0B618E);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border: none;
    /* margin-left: 50px; */
}

.getquotebtntoggle:hover,
.getquotebtntoggle {
    background-image: linear-gradient(#6EB7D7, #0B618E);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    border: none;
}

.togglesidebar {
    width: 25%;
}

.togglemenulist {
    width: fit-content;
    width: 40%;
}

.sublink {
    font-size: 14px;
    color: #000 !important;
    font-weight: 400;
}

.sublink:hover {
    color: #0B618E !important;
    background-color: transparent;
}

.sublink:active {
    background-color: transparent !important;
}

li {
    margin-top: 1rem !important;
}

@media only screen and (max-width: 6000px) {
    .getquotebtn {
        margin-left: 50px;
    }
}

@media only screen and (max-width: 3000px) {
    .getquotebtn {
        margin-left: 50px;
    }
}

@media only screen and (max-width: 2560px) {
    .getquotebtn {
        margin-left: 50px;
    }
}

@media only screen and (max-width: 1440px) {
    .headermenu {
        margin-left: 25px;
    }

    .getquotebtn {
        margin-left: 50px;
    }
}

@media only screen and (max-width: 1300px) {
    .headermenu {
        margin-left: 15px;
    }

    .getquotebtn {
        margin-left: 20px;
    }

    .header {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 1150px) {
    .togglebtn {
        display: inline !important;
    }

    .headermenu,
    .getquotebtn {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .togglesidebar {
        width: 40%;
    }
}

@media only screen and (max-width: 600px) {
    .togglesidebar {
        width: 50%;
    }
}

@media only screen and (max-width: 425px) {
    .togglesidebar {
        width: 85%;
    }

    .header {
        padding-left: 10px;
        padding-right: 10px;
    }

    li {
        margin-bottom: 5px !important;
        margin-top: 0 !important;
    }
}