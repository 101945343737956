.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    background-image: url('../../assets/contactus/contactusback.png');
}

.imagegradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0B618EB2;
    padding: 1px;
    z-index: 1;
}

.heading {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0
}

.content {
    color: #B7CAD3;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.contactinfo {
    position: absolute;
    top: 15%;
    left: 10%;
    z-index: 1;
    width: 70%;
}

.contactheading {
    font-size: 34px;
    font-weight: 500;
    color: #1A202C;
    text-align: center;
    margin-top: 3rem;
}

.inputfield {
    border: none;
    border-bottom: 1px solid #4A556880;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    color: #4A5568;
    font-size: 20px;
    font-weight: 500;
}

.submitbtn {
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.button {
    width: 100%;
}

.backimage {
    height: 665px;
    width: 100%;
}

@media only screen and (max-width:1024px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }

    .contactinfo {
        left: 5%;
    }
}

@media only screen and (max-width:900px) {
    .contactinfo {
        top: 8%;
    }

    .content {
        font-size: 14px;
    }
}

@media only screen and (max-width:768px) {
    .backimage {
        height: 580px;
    }

    .inputfield {
        margin-top: 2rem !important;
        font-size: 18px;
    }


    .contactheading {
        font-size: 30px;
    }
}

@media only screen and (max-width:767px) {
    .submitbtn {
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width:600px) {
    .backimage {
        height: 580px;
    }

    .contactinfo {
        top: 10%;
    }

    .content {
        font-size: 14px;
    }

    .contactheading {
        font-size: 28px;
    }
}

@media only screen and (max-width:576px) {
    .backimage {
        height: 450px;
    }
}

@media only screen and (max-width:425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }

    .contactheading {
        font-size: 25px;
        margin-top: 2rem;
    }

    .inputfield {
        font-size: 16px;
        margin-top: 1.5rem !important;
    }

    .submitbtn {
        font-size: 14px;
        margin-bottom: 2rem;
        margin-top: 2rem !important;
    }
}

@media only screen and (max-width:350px) {
    .content {
        font-size: 12px;
    }
}

@media only screen and (max-width:320px) {
    .contactheading {
        font-size: 20px;
        margin-top: 1.5rem;
    }

    .inputfield {
        font-size: 12px;
        margin-top: 1rem !important;
    }

    .submitbtn {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem !important;
        font-size: 12px;
    }
}