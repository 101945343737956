.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.technologydiv {
    background-color: #E1EFF6;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-left: 70px;
    padding-right: 70px;
}

.sevicediv {
    box-shadow: 0px 20px 90px 0px #07003B1F;
    padding: 35px;
    height: 367px;
    background-color: #FDFDFF;
    border-radius: 12px;
    overflow: hidden;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
}

.boldfont {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-top: 20px;
}

.contentfont {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    opacity: 0.7;
    margin-top: 20px;
}

.contentfont,
.boldfont {
    padding-left: 12px;
    padding-right: 12px;
}

@media only screen and (max-width: 1440px) {
    .sevicediv {
        padding: 25px;
    }
}

@media only screen and (max-width: 1300px) {
    .sevicediv {
        padding-top: 30px;
    }

    .contentfont,
    .boldfont {
        padding: 0;
    }
}


@media only screen and (max-width: 1024px) {
    .sevicediv {
        padding: 15px;
    }

    .contentfont {
        font-size: 15px;
    }

    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 30px;
    }

    .content {
        width: 80%;
        font-size: 20px;
        line-height: 32px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 990px) {
    .sevicediv {
        padding-top: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        font-size: 16px;
        width: 90%;
    }
}

@media only screen and (max-width: 767px) {
    .sevicediv {
        height: auto;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        width: 100%;
        line-height: 28px;
    }

    .sevicediv {
        padding-top: 30px;
    }

    .contentfont,
    .boldfont {
        margin-top: 10px;
    }
}