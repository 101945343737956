/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
  padding: 0%;
  margin: 0%;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.sidebar-open {
  margin-left: 275px;
  transition: 0.5s;
}

.sidebar-close {
  transition: 0.5s;
}

.shadow-below {
  box-shadow: 0 4px 2px -2px gray;
}

.shadow-right {
  box-shadow: 4px -2px 2px -2px gray;
}

.custom-accordion .accordion-body {
  padding: 0px 10px 16.5px 10px;
}

.accordion-button {
  border-radius: 20px !important;
  padding: 10px 15px !important;
  font-size: 15px;
  color: #0b618e;
  border: 1px solid #0b618e;
}

.accordion-button:focus {
  background-color: white;
  box-shadow: none;
}

.accordion-button:hover {
  background-color: #0b618e;
  color: white;
  transition: 0.5s;
}

.custom-accordion {
  background-color: #70b8d8;
  border-radius: 20px !important;
}

.accordion-button:not(.collapsed) {
  background-color: #0b618e !important;
  color: white !important;
  box-shadow: none !important;
}

.no-toggle-icon .accordion-button::after {
  display: none !important;
}

/* .accordion-button::before { */
/* color: #0b618e !important; */
/* } */

.listGroupitemDesign {
  border-radius: 20px !important;
  margin-top: 7px;
  color: #0b618e;
  border-color: #70b8d8;
}

.listGroupitemDesign:hover {
  transition: 0.5s;
  background-color: #0b618e;
  border-color: #0b618e;
  color: white;
  transition: 0.5s;
}

#ScrollBarSetting::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#ScrollBarSetting::-webkit-scrollbar {
  width: 4px;
  height: 5px;
  background-color: #F5F5F5;
}

#ScrollBarSetting::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #0b618e;
}