.maindiv {
    margin-top: 70px;
}

.client {
    box-shadow: 0px 20px 40px 0px #07003B1F;
    height: 312px;
    margin: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    justify-content: space-around;
    position: relative;
}

.sliderwidth {
    width: 80%;
    padding-bottom: 70px;
    padding-top: 70px;
}

.content {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
}

.clientname {
    font-size: 22px;
    font-weight: 700;
    color: #1A202C;
}

.clientrole {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.clientimage {
    position: absolute;
    bottom: -5%;
    right: 10%;
}

.navigationButton::after {
    content: none !important;
}

.navigationButton {
    border-radius: 50%;
    padding: 10px;
    width: 45px !important;
    height: 45px !important;
    border: 2px solid #0B618E;
}

@media only screen and (max-width: 1440px) {
    .content {
        font-size: 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        margin-top: 50px;
    }

    .sliderwidth {
        padding-top: 30px;
        padding-bottom: 30px;
        width: 90%;
    }

    .content {
        font-size: 14px;
    }

    .client {
        margin: 20px;
    }

    .clientimage {
        height: 100px;
        width: 100px;
    }
}

@media only screen and (max-width: 850px) {
    .client {
        margin: 10px;
        padding: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .content {
        font-size: 16px;
    }
}

@media only screen and (max-width: 425px) {
    .content {
        font-size: 14px;
    }

    .sliderwidth {
        padding-top: 0;
    }

    .maindiv {
        margin-top: 30px;
    }

    .navigationButton {
        height: 40px !important;
        width: 40px !important;
    }

    .client {
        margin: 20px;
    }

    .clientimage {
        height: 80px;
        width: 80px;
    }
}

@media only screen and (max-width: 375px) {
    .client {
        margin: 15px;
    }

    .content {
        font-size: 13px;
        line-height: 22px;
    }
}

@media only screen and (max-width: 320px) {
    .navigationButton {
        height: 30px !important;
        width: 30px !important;
        padding: 6px;
    }
}