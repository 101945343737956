.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 90px;
    transform: translateX(-50%);
    width: 185px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.careearimage {
    max-width: 100%;
    height: auto;
}

.content {
    color: #4A5568;
    font-size: 18px;
    font-weight: 600;
}

@media only screen and (max-width:1024px) {
    .heading {
        font-size: 35px;
    }

    .content {
        font-size: 16px;
    }

    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
    }
}

@media only screen and (max-width:768px) {
    .heading {
        font-size: 30px;
    }

    .careearimage {
        height: 100%;
    }

    .content {
        font-size: 15px;
    }

    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@media only screen and (max-width:425px) {
    .careearimage {
        height: 100%;
    }

    .content {
        font-size: 14px;
    }

    .maindiv {
        padding-left: 10px;
        padding-right: 0;
        padding-top: 30px;
    }
}

@media only screen and (max-width:375px) {
    .heading {
        font-size: 28px;
    }

}