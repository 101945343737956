.maindiv {
    margin-top: 60px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
    line-height: 36px;
    margin-bottom: 60px;
}

.subheading {
    color: #1A202C;
    font-size: 22px;
    font-weight: 700;
    width: 307px;
    text-align: center;
}

.subcontent {
    color: #4A5568;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 500;
    width: 307px;
    text-align: center;
}

.box1 {
    position: absolute;
    top: 0;
    left: 25%;
}

.box2 {
    position: absolute;
    top: 0;
    right: 41%;
}

.box4 {
    position: absolute;
    bottom: 0;
    left: 15%;
}

.box3 {
    position: absolute;
    top: 0;
    right: 23%;
}


.box5 {
    position: absolute;
    bottom: 0;
    left: 33%;
}

.box6 {
    position: absolute;
    bottom: 0;
    right: 32%;
}

.box7 {
    position: absolute;
    bottom: 0;
    right: 13%;
}

.flowchart {
    margin-top: 50px;
    position: relative;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flowimage {
    width: 100%;
}

@media only screen and (max-width: 1800px) {
    .box3 {
        right: 22%;
    }

    .box1 {
        left: 23%;
    }

    .box4 {
        left: 13%;
    }

    .box5 {
        left: 32%;
    }

    .box6 {
        right: 31%;
    }

    .box7 {
        right: 11%;
    }
}

@media only screen and (max-width: 1700px) {
    .box1 {
        left: 22%;
    }

    .box2 {
        right: 40%;
    }

    .box3 {
        right: 20%;
    }

    .box4 {
        left: 11%;
    }

    .box5 {
        left: 31%;
    }

    .box6 {
        right: 30%;
    }

    .box7 {
        right: 9%;
    }
}

@media only screen and (max-width: 1600px) {
    .box1 {
        left: 20%;
    }

    .box3 {
        right: 18%;
    }

    .box4 {
        left: 8%;
    }

    .box5 {
        left: 31%;
    }

    .box6 {
        right: 29%;
    }

    .box7 {
        right: 7%;
    }
}

@media only screen and (max-width: 1500px) {
    .box1 {
        left: 18%;
    }

    .box2 {
        right: 39%;
    }

    .box3 {
        right: 16%;
    }

    .box4 {
        left: 5%;
    }

    .box5 {
        left: 29%;
    }

    .box6 {
        right: 28%;
    }

    .box7 {
        right: 4%;
    }
}

@media only screen and (max-width: 1440px) {
    .box1 {
        left: 16%;
    }

    .box3 {
        right: 14%;
    }

    .box4 {
        left: 3%;
    }

    .box5 {
        left: 28%;
    }

    .box6 {
        right: 26%;
    }

    .box7 {
        right: 2%;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        margin-top: 40px;
    }

    .content {
        font-size: 20px;
        line-height: 32px;
        width: 80%;
    }

    .flowchart {
        margin-top: 0;
        height: 350px;
    }

    .subheading {
        font-size: 16px;
        width: fit-content;
    }

    .box1 {
        left: 14%;
    }

    .box2 {
        right: 46%;
    }

    .box3 {
        right: 18%;
    }

    .box4 {
        left: 6%;
    }

    .box5 {
        left: 33%;
    }

    .box6 {
        right: 30%;
    }

    .box7 {
        right: 3%;
    }
}

@media only screen and (max-width: 900px) {
    .flowchart {
        height: auto;
    }

    .subheading {
        font-size: 14px;
    }

    .box5 {
        top: 90%;
    }

    .box2 {
        right: 45%;
    }

    .box1 {
        left: 12%;
    }
}

@media only screen and (max-width: 768px) {

    .box1,
    .box2,
    .box3 {
        top: -3%;
    }

    .maindiv {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .content {
        width: 80%;
        font-size: 18px;
        margin-top: 20px;
        line-height: 28px;
    }
}

@media only screen and (max-width: 600px) {
    .subheading {
        font-size: 12px;
    }

    .box1 {
        left: 11%;
    }

    .box2 {
        right: 44%;
    }

    .box3 {
        right: 17%;
    }

    .box4 {
        left: 5%;
    }

    .box5 {
        left: 31%;
    }

    .box6 {
        right: 29%;
    }

    .box7 {
        right: 2%;
    }
}

@media only screen and (max-width: 500px) {
    .subheading {
        font-size: 10px;
    }
}

@media only screen and (max-width: 425px) {
    .box1 {
        width: 20%;
        top: -22%;
    }

    .box2,
    .box3 {
        top: -5%;
    }

    .box6 {
        right: 26%;
    }

    .content {
        width: 100%;
        font-size: 16px;
        margin-top: 20px;
        line-height: 26px;
    }
}

@media only screen and (max-width: 375px) {
    .box1 {
        top: -25%;
    }

    .box2,
    .box3 {
        top: -11%;
    }

    .box2 {
        right: 44%;
    }

    .box3 {
        right: 16%;
    }

    .box4,
    .box6,
    .box7 {
        top: 100%;
    }
}