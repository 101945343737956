@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.content {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    margin-top: 60px;
    padding-left: 80px;
    padding-right: 80px;
    padding-bottom: 30px;
    margin-bottom: 35px;
    background-color: white;
}

.bgdiv {
    /* background-color: rgb(34 34 34); */
    background: linear-gradient(300deg, #68B1D2 0%, #0B618E 100%);
    border-radius: 8px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    text-align: center;
    color: white;
}

.DiscussBtn{
    background-color: #0B618E;
    border-color: white;
    color: white;
    border: 2px solid #0B618E;
    box-shadow: 0px 0px 10px 5px white;
}

.setelement {
    padding: 0px 300px;
}

@media only screen and (max-width:1140px) {
    .maindiv {
        margin-top: 40px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .setelement {
        padding: 0px 180px;
    }
}


@media only screen and (max-width:1024px) {
    .maindiv {
        margin-top: 40px;
        padding-left: 58px;
        padding-right: 58px;
    }

    .setelement {
        padding: 0px 120px;
    }
}


@media only screen and (max-width:768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }

    .setelement {
        padding: 0px 30px;
    }
}

@media only screen and (max-width:425px) {
    .maindiv {
        margin-top: 30px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 20px;
    }

    .setelement {
        padding: 0px 20px;
    }

}