.maindiv {
    padding-top: 80px;
}

.rowpadding {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
    margin-bottom: 30px;
}

.rowcolor {
    padding-left: 70px;
    padding-top: 70px;
    background-color: #E1EFF6;
}

.software {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
}

.development {
    background: linear-gradient(#6EB7D7, #0B618E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.company {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.and {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
    line-height: 36px;
}

.spancontent {
    color: #4A5568;
    font-size: 20px;
    font-weight: 600;
    margin-top: 30px;
    line-height: 47px;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
    width: 80%;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.springimage {
    position: absolute;
    bottom: -190px;
    left: -75px;
    z-index: -1;
}

.webimage {
    max-width: 100%;
    height: auto;
}

.mapimage {
    height: 103%;
    width: 80%;
}

.choosewebimage {
    max-width: 100%;
    height: auto;
}

.getquotebtn:hover,
.getquotebtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    border: none;
}

@media only screen and (max-width: 1800px) {
    .springimage {
        bottom: -210px;
    }
}

@media only screen and (max-width: 1700px) {
    .springimage {
        bottom: -235px;
    }
}

@media only screen and (max-width: 1600px) {
    .springimage {
        bottom: -250px;
    }
}

@media only screen and (max-width: 1500px) {
    .springimage {
        bottom: -275px;
    }
}

@media only screen and (max-width: 1440px) {
    .springimage {
        width: 250px;
        bottom: -180px;
    }
}

@media only screen and (max-width: 1350px) {
    .springimage {
        bottom: -240px;
    }
}

@media only screen and (max-width: 1024px) {
    .rowpadding {
        padding-left: 50px;
        padding-right: 50px;
        padding-top:40px;
    }

    .springimage {
        display: none;
    }

    .heading {
        font-size: 35px;
    }

    .rowcolor {
        padding-top: 40px;
        padding-left: 50px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 35px;
    }

    .content {
        font-size: 17px;
    }

    .spancontent {
        font-size: 18px;
        line-height: 40px;
    }
}

@media only screen and (max-width: 768px) {
    .rowpadding {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }

    .heading {
        width: 100%;
    }

    .and,
    .company,
    .software,
    .development {
        font-size: 30px;
    }

    .content {
        font-size: 16px;
        line-height: 32px;
    }

    .heading {
        font-size: 30px;
    }

    .rowcolor {
        padding-top: 30px;
        padding-left: 40px;
    }

    .spancontent {
        font-size: 16px;
        line-height: 35px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 425px) {
    .rowpadding {
        padding-left: 10px;
        padding-right: 10px;
    }
    .spancontent {
        font-size: 14px;
        line-height: 30px;
        margin-top: 20px;
    }

    .rowcolor {
        padding-top: 10px;
        padding-left: 10px;
    }

    .content {
        line-height: 26px;
        font-size: 14px;
    }

    .getquotebtn {
        font-size: 14px;
    }
}

@media only screen and (max-width: 375px) {
    .heading {
        font-size: 28px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 28px;
    }
}

@media only screen and (max-width: 320px) {
    .heading {
        font-size: 26px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 26px;
    }
}