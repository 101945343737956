.numbercontent {
    color: #000000;
    font-size: 37px;
    font-weight: 500;
}

.detailcontent {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
    text-align: center;
}

.svg {
    background-color: #00FE93;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg1 {
    background-color: #FF586E;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg2 {
    background-color: #FFA808;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg3 {
    background-color: #24CCFF;
    border-radius: 50px;
    height: 67px;
    width: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.square {
    height: 200px;
    box-shadow: 0px 4px 29px 0px #6EB7D757;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
}

.companydetail {
    background-color: #E1EFF6;
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 70px;
    padding-bottom: 70px;
}

@media only screen and (max-width:1024px) {
    .companydetail {
        padding: 40px;
    }

    .square {
        height: 160px;
    }

    .svg,
    .svg1,
    .svg2,
    .svg3 {
        height: 60px;
        width: 60px;
    }

    .numbercontent {
        font-size: 26px;
    }

    .detailcontent {
        font-size: 16px;
    }
}

@media only screen and (max-width:900px) {
    .companydetail {
        padding: 30px;
    }
}

@media only screen and (max-width:768px) {
    .numbercontent {
        font-size: 24px;
    }

    .detailcontent {
        font-size: 14px;
    }
}

@media only screen and (max-width:600px) {
    .numbercontent {
        font-size: 22px;
    }

    .svg,
    .svg1,
    .svg2,
    .svg3 {
        height: 50px;
        width: 50px;
    }

    .svg svg {
        height: 30px;
    }

    .svg1 svg {
        height: 30px;
    }

    .svg2 svg {
        height: 30px;
    }

    .svg3 svg {
        height: 30px;
    }

    .square {
        height: 140px;
    }
}

@media only screen and (max-width:425px) {
    .companydetail {
        padding: 10px;
    }
}