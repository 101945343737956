@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  background-color: #FFF;
}

a {
  text-decoration: none;
  color: unset !important;
}

a:hover {
  color: #000;
}

.btn:focus {
  box-shadow: none;
}

.nav-link.active::after {
  background-color: #0B618E;
}

.row {
  --bs-gutter-x: 0 !important;
}

.tab-pane::-webkit-scrollbar {
  display: none;
}

.tab-pane {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid #4A556880;
  background-color: transparent;
}

.allcasestudy_maindiv__ojYiU .nav-pills .nav-link.active {
  background: linear-gradient(180deg, #68B1D2 0%, #0B618E 100%) !important;
  border-radius: 54px;
  color: #FFF;
}

.allcasestudy_maindiv__ojYiU .nav-link:hover {
  color: #4A5568;
}

.allcasestudy_maindiv__ojYiU .nav-link:focus .allcasestudy_maindiv__ojYiU .nav-link:hover {
  color: #4A5568;
}

.testmonials_maindiv__1MXzY .swiper {
  position: unset;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: none !important;
}

.wordsfromclientele_maindiv__1orUx .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 5%) !important;
  z-index: 0;
}

.wordsfromclientele_maindiv__1orUx .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 5%) !important;
  z-index: 0;
}

.modal-content {
  border: none;
}

/* file upload */

.custom-file-input {
  display: none;
}

/* heading */

.heading {
  color: var(--Gray-gray-900, #1A202C);
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  text-align: center;
  line-height: normal;
}

.headingborder::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 185px;
  height: 2px;
  background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

@media only screen and (max-width: 1024px) {
  .wordsfromclientele_maindiv__1orUx .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 2%) !important;
  }

  .wordsfromclientele_maindiv__1orUx .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 2%) !important;
  }
}

@media only screen and (max-width: 425px) {
  .wordsfromclientele_maindiv__1orUx .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, 0%) !important;
  }

  .wordsfromclientele_maindiv__1orUx .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, 0%) !important;
  }
}

@media (min-width: 1024px) {
  .modal-dialog {
    max-width: 950px !important;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 1024px) {
  .heading {
    font-size: 35px;
  }
}

@media (min-width: 990px) {
  .modal-dialog {
    max-width: 750px;
    margin: 1.75rem auto;
  }
}

@media only screen and (max-width: 768px) {
  .tab-pane {
    overflow: auto;
  }

  .heading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 425px) {
  .modal {
    height: calc(100% - 1rem);
    margin: 7px auto;
  }

  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 375px) {

  .heading {
    font-size: 28px;
  }
}

@media only screen and (max-width: 320px) {

  .heading {
    font-size: 26px;
  }
}