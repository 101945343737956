.maindiv {
    width: 100%;
}

.footer {
    background: #FFF;
    box-shadow: 0px 4px 53px 0px rgba(110, 183, 215, 0.20);
    padding-left: 60px;
    padding-right: 60px;
}

.content {
    font-size: 16px;
    font-weight: 500;
    color: #4A5568;
}

.footercontent {
    font-size: 18px;
    font-weight: 500;
    color: #4A5568;
    padding: 20px;
    padding-left: 0;
}

.copyright {
    background-color: #FFF;
    height: 70px;
    border-top: 1px solid #CBD5E0;
    display: flex;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
}

.round {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #FAFAFA;
    box-shadow: 0px 4px 8px 0px #68B1D261;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.heading {
    color: #4A5568;
    font-size: 22px;
    font-weight: 700;
}

.servicesection,
.hrsection {
    width: fit-content;
}

@media only screen and (max-width:1200px) {
    .footercontent {
        font-size: 16px;
    }

    .heading {
        font-size: 20px;
    }

    .content {
        font-size: 14px;
    }
}

@media only screen and (max-width:1024px) {
    .footer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .copyright {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media only screen and (max-width:425px) {
    .footer {
        padding-left: 10px;
        padding-right: 10px;
    }

    .round {
        margin: 5px;
    }

    .copyright {
        padding-left: 10px;
        padding-right: 10px;
    }
}