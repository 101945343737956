.maindiv {
    padding-top: 140px;
}

.rowpadding {
    padding-left: 70px;
    padding-right: 70px;
}

.software {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 300;
}

.development {
    background: linear-gradient(#6EB7D7, #0B618E);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.company {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 800;
}

.and {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
}

.container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.content {
    font-size: 18px;
    font-weight: 400;
    color: #4A5568;
    width: 60%;
}

.getquotebtn:hover,
.getquotebtn {
    border-radius: 5px;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    border: none;
}

.heading {
    color: var(--Gray-gray-900, #1A202C);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.contentfont,
.boldfont {
    padding-left: 12px;
    padding-right: 12px;
}

.roundspringimg {
    position: absolute;
}

.sevicediv {
    padding: 20px;
    padding-top: 85px;
    height: 100%;
    background-color: #FDFDFF;
    filter: drop-shadow(0px 50px 90px rgba(7, 0, 59, 0.12));
    border-radius: 12px;
}

.oversvg {
    position: absolute;
    top: 14px;
    left: 12px;
}

.headingborder::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 185px;
    height: 2px;
    background: linear-gradient(90deg, #0B618E 0.53%, #6EB7D7 100%);
}

.springimage {
    position: absolute;
    bottom: -205px;
    left: -75px;
    z-index: -1;
}

@media only screen and (max-width: 1780px) {
    .springimage {
        bottom: -220px;
    }
}

@media only screen and (max-width: 1700px) {
    .springimage {
        height: 400px;
        bottom: -180px;
    }
}

@media only screen and (max-width: 1650px) {
    .springimage {
        height: 350px;
        bottom: -150px;
    }
}

@media only screen and (max-width: 1550px) {
    .springimage {
        height: 255px;
        bottom: -119px;
    }
}

@media only screen and (max-width: 1400px) {
    .springimage {
        height: 255px;
        bottom: -120px;
    }
}

@media only screen and (max-width: 1300px) {
    .springimage {
        height: 200px;
        bottom: -75px;
    }
}

@media only screen and (max-width: 1200px) {
    .springimage {
        display: none;
    }

    .rowpadding {
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 1024px) {

    .rowpadding {
        padding-left: 50px;
        padding-right: 50px;
    }

    .software,
    .development,
    .company,
    .and {
        font-size: 35px;
    }

    .content {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .rowpadding {
        padding-left: 40px;
        padding-right: 40px;
    }

    .content {
        font-size: 16px;
        width: 100%;
    }

    .and,
    .company,
    .software,
    .development {
        font-size: 30px;
    }
}

@media only screen and (max-width: 425px) {
    .rowpadding {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        font-size: 16px;
        width: 100%;
    }

    .and,
    .company,
    .software,
    .development {
        font-size: 30px;
    }
}

@media only screen and (max-width: 375px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 28px;
    }
}

@media only screen and (max-width: 320px) {

    .and,
    .company,
    .software,
    .development {
        font-size: 26px;
    }
}