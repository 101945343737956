.maindiv {
    margin-top: 3rem;
    padding-left: 70px;
    padding-right: 70px;
}

.servicebox {
    position: relative;
    background-color: #FDFDFF;
    padding: 50px;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    height: 313px;
    width: 100%;
    overflow: hidden;
}

.roundimage {
    position: absolute;
    top: 0;
    right: 0;
}

.servicecontent {
    font-size: 16px;
    font-weight: 500;
    margin-top: 40px;
    line-height: 25px;
}

.serviceheading {
    font-size: 28px;
    font-weight: 700;
    color: #1A202C;
}

@media only screen and (max-width: 1200px) {
    .servicebox {
        padding: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
    }

    .roundimage {
        height: 80px;
        width: 80px;
    }

    .serviceheading {
        font-size: 26px;
    }

    .servicebox {
        padding: 20px;
    }

    .servicecontent {
        font-size: 15px;
        margin-top: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .roundimage {
        height: 60px;
        width: 60px;
    }

    .serviceheading {
        font-size: 24px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
    }

    .servicecontent {
        font-size: 14px;
        margin-top: 12px;
    }

    .serviceheading {
        font-size: 22px;
    }

    .serviceimage {
        height: 55px;
        width: 55px;
    }
}

@media only screen and (max-width: 700px) {
    .serviceheading {
        font-size: 20px;
    }

    .servicecontent {
        line-height: 22px;
        margin-bottom: 0;
    }

    .roundimage {
        height: 50px;
        width: 50px;
    }
}

@media only screen and (max-width: 576px) {
    .servicebox {
        height: auto;
    }
}

@media only screen and (max-width: 425px) {
    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }
}