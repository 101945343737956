@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.boxcontent {
    font-family: 'DM Sans', sans-serif;
}

.maindiv {
    padding-left: 70px;
    padding-right: 70px;
    padding-top: 60px;
}

.technologydiv {
    background-color: #E1EFF6;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-left: 70px;
    padding-right: 70px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
}

.submitbtn,
.submitbtn:hover {
    font-size: 21px;
    font-weight: 500;
    background: linear-gradient(180deg, #6EB7D7 0%, #0B618E 100%);
    color: #F2F2F2;
    border: none;
}

.number {
    font-size: 85px;
    font-weight: 600;
    color: #68B1D233;
}

.box {
    height: 100%;
    border: 1.5px solid #0B618E;
    background-color: #FDFDFF;
    box-shadow: 0px 20px 90px 0px #07003B1F;
    border-radius: 12px;
    padding: 20px;
}

.numbercontent {
    color: #1A202C;
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    top: 40%;
    margin-left: 10px;
}

.boxcontent {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

@media only screen and (max-width: 1440px) {
    .number {
        font-size: 80px;
    }

    .content {
        width: 75%;
    }
}

@media only screen and (max-width: 1024px) {
    .number {
        font-size: 70px;
    }

    .boxcontent {
        font-size: 15px;
    }

    .box {
        padding: 16px;
    }

    .maindiv {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 40px;
    }

    .content {
        width: 80%;
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
    }

    .submitbtn {
        font-size: 18px;
    }
}

@media only screen and (max-width: 768px) {
    .maindiv {
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 30px;
    }

    .number {
        font-size: 60px;
    }

    .content {
        font-size: 18px;
        width: 90%;
    }

    .numbercontent {
        font-size: 18px;
    }

    .boxcontent {
        font-size: 14px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 425px) {
    .content {
        font-size: 16px;
        width: 100%;
    }

    .maindiv {
        padding-left: 10px;
        padding-right: 10px;
    }
}