.maindiv {
    margin-top: 60px;
    margin-bottom: 60px;
}

.technologydiv {
    background-color: #E1EFF6;
    padding-bottom: 50px;
    padding-top: 50px;
    padding-left: 70px;
    padding-right: 70px;
}

.content {
    color: #4A5568;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    width: 60%;
    margin-top: 30px;
}

.servicecontent {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
}

.langmenudiv::-webkit-scrollbar {
    display: none;
}

.langmenudiv {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.langdiv {
    background-color: #FFFFFF;
    border-radius: 10px;
    height: 170px;
    width: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.technologydiv::-webkit-scrollbar {
    display: none;
}

.technologydiv {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@media only screen and (max-width: 1440px) {
    .technologydiv {
        padding-left: 50px;
        padding-right: 50px;
    }

    .langdiv {
        height: 160px;
        width: 160px;
    }
}

@media only screen and (max-width: 1100px) {
    .langdiv {
        height: 140px;
        width: 140px;
    }
}

@media only screen and (max-width: 1024px) {
    .heading {
        font-size: 35px;
    }

    .technologydiv {
        overflow: auto;
    }

    .langmenu {
        width: 1024px;
    }

    .maindiv {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .content {
        font-size: 20px;
        width: 80%;
        line-height: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .heading {
        font-size: 30px;
    }

    .maindiv {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .content {
        font-size: 18px;
        width: 100%;
        line-height: 28px;
        padding-left: 40px;
        padding-right: 40px;
    }

    .technologydiv {
        padding-left: 40px;
        padding-right: 40px;
    }

    .langdiv {
        height: 130px;
        width: 130px;
    }

    .langmenu {
        width: 900px;
    }

    .servicecontent {
        font-size: 16px;
    }
}

@media only screen and (max-width: 425px) {
    .content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .content {
        font-size: 16px;
    }

    .technologydiv {
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 320px) {
    .heading {
        font-size: 28px;
    }
}